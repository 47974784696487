import React, { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
// Import marker icon and shadow
import icon from "leaflet/dist/images/marker-icon.png";

export default function Map(props) {
  const { data, center, loadUnitsData } = props;
  const [map, setMap] = useState(null);
  let defaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  useEffect(() => {
    // initialize map only once
    // this will change when we use dynamic zoom level
    if (map) return;
    let initMap = L.map("map", {
      center: [center[0], center[1]],
      zoom: 13,
      attributionControl: false,
    });

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(initMap);

    setMap(initMap);
  }, []);

  useEffect(() => {
    if (!map) return;

    // Add markers for each building
    data.forEach((building) => {
      const marker = L.marker([building.LatLong[0], building.LatLong[1]], {
        icon: defaultIcon,
      }).addTo(map);
      marker.bindPopup(building.name);
      // Open popup on mouseover
      marker.on("mouseover", function (e) {
        this.openPopup();
      });
      // Close popup on mouseout
      marker.on("mouseout", function (e) {
        this.closePopup();
      });
      marker.on("click", () => {
        if (props.loadUnitsData !== undefined) {
          loadUnitsData(building.buildingId);
        }
      });
    });
  }, [map, data]);

  useEffect(() => {
    if (map && center) {
      map.flyTo([center[0], center[1]], 13);
    }
  }, [map, center]);

  return (
    /*container mx-auto mt-32 mb-60 size-1/2 border-4 border-black relative z-10 <--- testing */
    <div className="size-1/2 relative z-10 ">
      <div id="map" style={{ height: 350, width: 350 }} />
    </div>
  );
}
