import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import "./login.css";

import HomeIcon from "@mui/icons-material/Home";
import TableChartIcon from "@mui/icons-material/TableChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import SettingsIcon from "@mui/icons-material/Settings";
import LoginIcon from "@mui/icons-material/Login";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AddAlertIcon from "@mui/icons-material/AddAlert";

const LoginComponent = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const invitation = searchParams.get("invitation");
  const organization = searchParams.get("organization");

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        ...(invitation && {
          authorizationParams: { invitation, organization },
        }),
      });
    }
  }, [loginWithRedirect, isAuthenticated]);

  return <div className="login-container"></div>;
};

export default LoginComponent;
