import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";

import React, { useState, useEffect } from "react";
import SingleStackedBar from "../../components/SingleStackedHorizontalBarGraph";
import { GlobalFilter } from "./buildingEventsTable";
import Map from "../../components/SelectableMap";

export default function UnitEventsTable(props) {

  // @ts-ignore
  const graphData = ({ value, columnProps }) => {
    return (
      <div className="w-96 block">
        <SingleStackedBar data={value} />
      </div>
    );
  };


  const center = props.building.LatLong // Cambridge, Ontario

  const markers = [
    {
      id: 1,
      position: props.building.LatLong,
      title: "Cambridge City Hall"
    }
  ];

  const sortGraph = (rowA, rowB) => {
    if (
      rowA.original.intensity +
        rowA.original.distraction +
        rowA.original.forgetfulness >
      rowB.original.intensity +
        rowB.original.distraction +
        rowB.original.forgetfulness
    )
      return 1;
    if (
      rowB.original.intensity +
        rowB.original.distraction +
        rowB.original.forgetfulness >
      rowA.original.intensity +
        rowA.original.distraction +
        rowA.original.forgetfulness
    )
      return -1;
    return 0;
  };

  const handleRowClick = (row) => {
    // props.loadBuildingData(row.buildingId);
  };

  // const displayAverage = ({ value, columnProps }) => {
  //   return <div>{value}</div>;
  // };

  const columns = React.useMemo(
    () => [
      {
        Header: "Unit",
        accessor: "UnitNumber", // accessor is the "key" in the data
        filter: "fuzzyText",
        isVisible: true,
      },
      {
        Header: "Sensor ID",
        accessor: "SensorID", // accessor is the "key" in the data
        filter: "fuzzyText",
        isVisible: true,
      },
      {
        Header: "Status",
        accessor: "Status", // accessor is the "key" in the data
        Cell: ({ value }) => {
          return (
            <div
              style={{
                color: value === "Online" ? "green" : "red",
                fontWeight: "bold",
              }}
            >
              {value}
            </div>
          );
        },
        isVisible: true,
      },
      {
        Header: "Number of Sessions",
        accessor: "NumberOfSessions", // accessor is the "key" in the data
        isVisible: false,
      },
      {
        Header: "Average Session Length (s)",
        accessor: "AvgSessionLength", // accessor is the "key" in the data
        isVisible: false,
      },
      {
        Header: "Max Session Intensity",
        accessor: "MaxSessionIntensity", // accessor is the "key" in the data
        isVisible: false,
      },
      {
        Header: "Warnings",
        accessor: "Warnings", // accessor is the "key" in the data
        isVisible: false,
      },
      {
        Header: "Alarms",
        accessor: "Alarms", // accessor is the "key" in the data
        isVisible: false,
      },
      {
        Header: "User Alarm Responses",
        accessor: "AlarmResponses", // accessor is the "key" in the data
        isVisible: false,
      },
      {
        Header: "Presence Counter (s)",
        accessor: "PresenceCounter", // accessor is the "key" in the data
        isVisible: false,
      },
      {
        Header: "Presence Ratio",
        accessor: "PresenceRatio", // accessor is the "key" in the data
        isVisible: false,
      },
      {
        Header: "Exposed Burner Accumulated Time (s)",
        accessor: "ExposedBurnerAccTime", // accessor is the "key" in the data
        isVisible: false,
      },
      {
        Header: "Too Hot Accumulated Time (s)",
        accessor: "TooHotAccTime", // accessor is the "key" in the data
        isVisible: false,
      },
      {
        Header: "Fire",
        accessor: "Fire", // accessor is the "key" in the data
        isVisible: false,
      },
    ],
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      //fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    preGlobalFilteredRows,
    setGlobalFilter,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, hiddenColumns },
    //Added TS ignore Outdated ts support in for this library
    // @ts-ignore
  } = useTable(
    {
      columns,
      data: props.data,
      initialState: {
        hiddenColumns: columns
          .filter((column) => !column.isVisible)
          .map((column) => column.accessor),
        sortBy: [{ id: "UnitNumber" }],
        pageIndex: 0,
      },
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  //   useEffect(() => {
  //     if(props.data.length>0)
  //     {
  //       console.log(props.data)
  //       console.log(props.building.name)
  //     }
  //  }, [props.data]); // Re-run whenever 'props.data' changes

  return (
    <>
      <div className="container m-auto mt-6">
        <h1 className="text-2xl">Property: {props.building.name}</h1>
        <div style={{ height: '20px' }} />
        <div className="container-layout">
          <div style={{ flexGrow: 0 }}>

          <table
            className="drop-shadow-lg"
            {...getTableProps()}
            style={{ border: "solid 1px black" }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        // @ts-ignore
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          borderBottom: "solid 3px black",
                          background: "white",
                          color: "black",
                          fontWeight: "bold",
                          padding: "10px",
                        }}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
              <tr>
                <th
                  style={{
                    textAlign: "left",
                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    onClick={() => handleRowClick(row.original)}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "10px",
                            border: "solid 1px gray",
                            background: i % 2 === 0 ? "#EEEEEE" : "white",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>


          {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}


          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <>
              {Array.from({ length: 5 }, (_, i) => {
                const pageNumber = pageIndex + 1 + i;
                const isDisabled = pageNumber > pageOptions.length;

                return (
                  <button
                    key={pageNumber}
                    disabled={isDisabled}
                    onClick={() => !isDisabled && gotoPage(pageNumber - 1)}
                  >
                    {pageNumber}
                  </button>
                );
              })}
            </>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            {/* <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select> */}
          </div>
          {/* END OF Pagination */}
        </div>
        <Map data={[props.building]} center={center} />
        </div>
        </div>
       

    </>
  );
}
