// this file is the get data service.

export function buildEventsAllBuildings(propertyData) {
  var output = [];
  for (
    var buildingIndex = 0;
    buildingIndex < propertyData.length;
    buildingIndex++
  ) {
    let oneBuildingSum = {
      buildingId: propertyData[buildingIndex].id, // buildingId
      name: propertyData[buildingIndex].name, // name
      LatLong: [
        propertyData[buildingIndex].location.latitude,
        propertyData[buildingIndex].location.longitude,
      ],
    };

    output = output.concat(oneBuildingSum);
  }
  return output;
}

export function sumOfSessions(myArray) {
  var NumberOfSessions = 0;
  for (var index = 0; index < myArray.length; index++) {
    NumberOfSessions = NumberOfSessions + myArray[index].NumberOfSessions;
  }
  return NumberOfSessions;
}
export function sumSessionLengths(myArray) {
  var sumSessionLength = 0;
  for (var index = 0; index < myArray.length; index++) {
    sumSessionLength =
      sumSessionLength +
      myArray[index].AvgSessionLength * myArray[index].NumberOfSessions;
  }
  return sumSessionLength;
}
export function getNumberOfUnitsPerFloor(numberOfAppartments, rng) {
  if (numberOfAppartments === 50) {
    if (rng() > 0.5) return 10;
    return 5;
  }
  const possibleUnits = [10, 20, 25];
  return possibleUnits[Math.round(0 + rng() * (2 - 0))];
}

export function getBuildingIndex(data, buildingId) {
  var dataBuildingIndex = 0;
  for (var i = 0; i < data.length; i++) {
    if (data[i].buildingId === buildingId) {
      dataBuildingIndex = i;
      break;
    }
  }
  return dataBuildingIndex;
}

export function getNumberOfUnitsPerBuilding(buildingId) {
  const possibleUnits = [50, 110, 160, 250];
  return possibleUnits[100 % 4];
}

export function calcUnitId(numberOfUnitsPerFloor, apartmentIndex) {
  const floorIndex = Math.floor(apartmentIndex / numberOfUnitsPerFloor) + 1;
  const unitIndex = apartmentIndex % numberOfUnitsPerFloor;
  return floorIndex * 100 + unitIndex + 1;
}
