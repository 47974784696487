import React, { useState } from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import "./Navbar.css";
import LoginIcon from "@mui/icons-material/Login";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "styled-components";
import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from "@mui/icons-material/BarChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import TableChartIcon from "@mui/icons-material/TableChart";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import SearchIcon from "@mui/icons-material/Search";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import logo from "../../images/cooktop_logo.png";

const StyledNav = styled.nav`
  display: flex;
  font-size: 1.2rem;
  font-family: "PT Sans", sans-serif;
`;
const StyledContainer = styled.div`
  height: 60px;
  justify-content: center;
  font-size: 1.2rem;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #212121;
`;

const StyledLink = styled(Link)`
  color: #fff;
  height: 80px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin-left: 40px;
  margiin-top: 80px;
  cursor: pointer;
  justify-self: start;
`;

function Navbar() {
  const [click, setClick] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false);
  };

  const handleSettingsExpand = () => {
    setOpenSettings(!openSettings);
  };

  const handleAdminExpand = () => {
    setOpenAdmin(!openAdmin);
  };
  return (
    <>
      <StyledContainer>
        <div className="w-full nav-width">
          <div className="inline-block">
            <img
              className="m-auto pt-3 ml-6"
              src={logo}
              alt="Cook Top Safety"
              height={100}
              width={120}
            />
            {/* <StyledLink
            to="/"
            className="navbar-logo"
            onClick={closeMobileMenu}
          ></StyledLink> */}
          </div>
          <div className="inline-block mp-9 mt-6 align-right float-right h-max">
            <StyledNav>
              <div className="inline-block  mp-9 align-right float-right h-max">
                <Link
                  className="menu-icon4 mr-3"
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  {isAuthenticated ? (
                    <>
                      <LogoutIcon className="text-white" />
                      <span style={{ color: "white" }}>Logout</span>
                    </>
                  ) : (
                    <>
                      <LoginIcon className="text-white" />
                      <span
                        style={{ color: "white" }}
                        onClick={() => loginWithRedirect()}
                      >
                        Log In
                      </span>
                    </>
                  )}
                </Link>
              </div>

              <Button />
            </StyledNav>
          </div>
        </div>
      </StyledContainer>
    </>
  );
}

export default Navbar;
